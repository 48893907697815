<template>
  <div class="user page">
    <Nav></Nav>
    <main class="wrap">
      <div class="main">
        <div class="m-panel">
          <div class="hd">
            收货人信息
          </div>
          <div class="bd">
            <div class="l">
              <div class="item">
                <div class="label">收 货 人：</div>
                <div class="txt">{{ order.consigneeName }}</div>
              </div>
              <div class="item">
                <div class="label">联系方式：</div>
                <div class="txt">{{ order.consigneeMobile }}</div>
              </div>
              <div class="item">
                <div class="label">收货地址：</div>
                <div class="txt">{{ order.consigneeProvince }}{{ order.consigneeCity }}{{ order.consigneeArea }}{{
                  order.consigneeAddress }}</div>
              </div>
            </div>
            <div class="r">
            </div>
          </div>
        </div>
        <div class="m-panel">
          <div class="hd">
            <div class="info">商品信息</div>
            <div class="t1">单价</div>
            <div class="t1">数量</div>
            <div class="t1">小计</div>
          </div>
          <div>
            <div class="goods" v-for="(item, index) in order.products" :key="index">
              <div class="img"><img :src="item.productImgUrl" width="100" height="100" /></div>
              <div class="title">
                <div style="height: 25px;">
                  {{ item.productName }}
                </div>
                <div style="color: #999;">{{ item.specName }}</div>
              </div>
              <div class="pr">¥{{ item.productPrice }}</div>
              <div class="t1">{{ item.productCount }}</div>
              <div class="pr">¥{{ item.productPrice * item.productCount }}</div>
            </div>
          </div>
        </div>
        <div class="m-panel pd10">
          <div class="m-itemInfoFt">
            <div class="m-itemsInfo">
              <div class="label">订单号码：</div>
              <div class="txt">{{ order.number }}</div>
            </div>
          </div>
          <div class="m-itemInfoFt">
            <div class="m-itemsInfo">
              <div class="label">下单时间：</div>
              <div class="txt">{{ order.createTime }}</div>
            </div>
          </div>
          <div class="m-itemInfoFt">
            <div class="m-itemsInfo">
              <div class="label">商品金额：</div>
              <div class="txt">¥{{ order.productPrice }}</div>
            </div>
          </div>
          <div class="m-itemInfoFt">
            <div class="m-itemsInfo">
              <div class="label">邮 费：</div>
              <div class="txt">¥{{ order.deliveryFee }}</div>
            </div>
          </div>
          <div class="m-itemInfoFt">
            <div class="m-itemsInfo">
              <div class="label">应付：</div>
              <div class="price">¥{{ order.amountPay }}</div>
            </div>
          </div>
          <div class="m-itemInfoFt">
            <a-button type="default" class="order" shape="round" size="large" v-if="order.state == '待发货'" @click="cacel"
              block>取消订单</a-button>
            <a-button type="danger" class="order" shape="round" size="large" v-if="order.state == '待付款'" @click="pay"
              block>立即付款</a-button>
          </div>
        </div>
      </div>
    </main>
    <Foot></Foot>
  </div>
</template>
<script>


import Foot from "@/components/foot";
import Nav from "@/components/Nav.vue";

export default {
  components: {
    Nav,
    Foot,
  },
  data() {
    return {
      orderNo: '',
      order: {},
    };
  },
  computed: {},
  created() {
    if (this.$route.name === "Home") this.main_swiper = true;
    let geturl = window.location.href;
    let getqyinfo = geturl.split("?")[1];
    let getqys = getqyinfo.split("&");
    let obj = {}; //创建空对象，接收截取的参数
    for (let i = 0; i < getqys.length; i++) {
      let item = getqys[i].split("=");
      let key = item[0];
      let value = item[1];
      obj[key] = value;
    }
    this.orderNo = obj.orderNo;
    this.loadData();
  },
  mounted() {

  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "fetchDate",
  },
  methods: {
    fetchDate() {
      if (this.$route.name !== "Home") {
        this.main_swiper = false;
      } else {
        this.main_swiper = true;
      }
    },
    loadData() {
      this.api.getorderdetail({ orderNo: this.orderNo, }).then((res) => {
        this.order = res;
      }).catch((err) => {
        console.log(err);
      });
    },
    cacel() {
      this.$message.error("操作失败：订单暂时不能取消", 3);
    },
    pay() {
      this.$router.push("/orderPaymethod?orderNo=" + this.orderNo);
    },
  },
};
</script>


<style scoped>
.page {
  background-color: #fff;
}

.main .m-panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
}

.main .pd10 {
  padding-top: 50px;
  padding-bottom: 100px;
  background-color: #f5f5f5;
}

.main .m-panel .hd {
  padding-left: 21px;
  background-color: #f5f5f5;
  height: 42px;
  border-bottom: 1px solid #e8e8e8;
  line-height: 41px;
  color: #999;
  font-size: 14px;
  display: flex;
}

.main .m-panel .hd .info {
  width: 100%;
}

.main .m-panel .hd .t1 {
  width: 260px;
  text-align: center;
}

.main .m-panel .bd {
  padding: 30px 0;
  display: flex;
}

.main .m-panel .bd .l {
  width: 100%;
}

.main .m-panel .bd .r {
  padding-top: 30px;
  height: 140px;
  width: 300px;
  text-align: center;
}

.main .m-panel .bd .item {
  display: flex;
  height: 40px;
  line-height: 40px;
}

.main .m-panel .bd .item .label {
  width: 150px;
  color: #999;
  text-align: center;
}

.main .m-panel .bd .item .txt {}


.main .m-panel .goods {
  margin-bottom: 5px;
  background-color: #fffbf0;
  display: flex;
  height: 120px;
  line-height: 120px;
  padding-left: 20px;
  overflow: hidden;
}


.main .m-panel .goods .img {
  width: 180px;
}

.main .m-panel .goods .title {
  height: 40px;
  line-height: 40px;
  padding-top: 10px;
  padding-left: 10px;
  width: 100%;
}

.main .m-panel .goods .pr {
  width: 300px;
  text-align: center;
  color: #d4282d;
}

.main .m-panel .goods .t1 {
  width: 300px;
  text-align: center;
}

.main .m-panel .m-itemInfoFt {
  padding: 10px 50px;
  background-color: #f5f5f5;
  display: flex;
}

.main .m-panel .m-itemInfoFt .m-itemsInfo {
  margin-bottom: 10px;
  display: flex;
  margin-left: auto;
  margin-right: 0;
}

.main .m-panel .m-itemInfoFt .m-itemsInfo .label {
  width: 90px;
  text-align: right;
}

.main .m-panel .m-itemInfoFt .m-itemsInfo .txt {
  width: 160px;
  text-align: right;
}

.main .m-panel .m-itemInfoFt .m-itemsInfo .price {
  width: 160px;
  text-align: right;
  font-weight: 700;
  color: #d4282d;
  font-size: 20px;
}

.main .m-panel .m-itemInfoFt .order {
  width: 150px;
  height: 60px;
  font-size: 18px;
  margin-left: auto;
}
</style>
